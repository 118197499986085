import React, { useState,useRef } from 'react';
import './PasswordReset.css';
import { useNavigate } from 'react-router-dom';
import { backend_path } from "../../constants/backend";
import axios from 'axios';
import logo from '../../asset/alconesy_logo.webp';


const PasswordReset = () => {

  const navigate = useNavigate();  // Initialize useNavigate

  const emailRef = useRef(null);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPasswod, setConfirmPasswod] = useState('');
  const [otp, setOtp] = useState('');
  const [submit,setSubmit]=useState(false);
  const [emailErr,setEmailErr]=useState("")
  const [otpErr,setOtpErr]=useState("")

  const [loading,setLoading]=useState(false)

  const [isOtpValid,setIsValidOtp]=useState(false);
  const [timer,setTimer]=useState(60);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      email,
      otp
    };
    setLoading(true)
    
    // const otpBtn = e.target.querySelector('input[type="submit"]');
    // const otpBtnName = otpBtn.getAttribute('name');
    // if(e.target)
    if(e.target.name==="verify_otp"){
      console.log("Form Data: ", formData);
      const res=await axios.post(`${backend_path}/verifyotp`,{email:email,otp:otp})
      if(res.data.status=="success"){
        navigate("/change_password")
      }else{
        alert("Wrong OTP")
        navigate("/login")
      }
      
    }else{
      const res=await axios.post(`${backend_path}/sendotp`,{email:email})
      console.log(res.data)
      if(res.data.status==="Email not found"){
        setEmailErr(res.data.status)
        setLoading(false)
      }
      if(res.data.status==="success"){
        setSubmit(true)
        sessionStorage.setItem("email",email)
        // sessionStorage.setItem("otp",otp)
        setLoading(false)
        console.log("Form Data: ", formData);
      }
    }

    // setEmail(formData.email)
    // setSubmit(true)
    // sessionStorage.setItem("email",email)
    // sessionStorage.setItem("otp",otp)
    // console.log("Form Data: ", formData);

  };

  return (
    <div className='pswd_reset'>
      <div className='pswd_reset_whole_container'>
        <div className='pswd_reset_form_container'>
          <div className='pswd_reset_heading_container'>
            <p className='pswd_reset_heading'>PASSWORD RESET</p>
          </div>
          <div className='pswd_reset_description'>
          
              <p className='pswd_reset_desc'>
              { !submit ? "Please enter valid email to reset the password":"OTP has been sent to your mail, Please check your mail"}
            </p>
           
            
          </div>
          <form name={!submit?"send_otp":"verify_otp"} className='pswd_reset_form' onSubmit={handleSubmit}>
          {/* <form className='pswd_reset_form' onSubmit={handleSubmit}> */}
          { !submit &&  
            <div className='pswd_reset_form_row'>
              <input
                type="email"
                className='pswd_reset_form_input'
                name="email"
                ref={emailRef}
                value={email}
                
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Email' required
              />
              {emailErr && <p className='reg_err_msg'>{emailErr}</p>}
            </div>
            }
            { submit && !isOtpValid &&
              <>
                <div className='pswd_reset_form_row'>
                <input
                  type="number"
                  className='pswd_reset_form_input'
                  name="otp"
                  maxLength={4}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder='Enter OTP' required
                />
                {otpErr && <p className='reg_err_msg'>{otpErr}</p>}
                </div>
                <div className='pswd_reset_otp'>
                  <a className='' href='/'>Resend otp</a>
                </div>
              </>
              }
            {/* {
              otp &&
            } */}
            { isOtpValid &&

                <>
                  <div className='pswd_reset_form_row'>
                    <input
                        type="text"
                        className='pswd_reset_form_input'
                        name="new_password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder='Enter new password' required
                    />
                  </div>
                  <div className='pswd_reset_form_row'>
                    <input
                        type="text"
                        className='pswd_reset_form_input'
                        name="confirm_password"
                        value={confirmPasswod}
                        onChange={(e) => setConfirmPasswod(e.target.value)}
                        placeholder='Enter confirm password' required
                    />
                  </div>
                </>

            }
           { !loading?(!submit ?
                <div className='pswd_reset_form_btn'>
                  <button type="submit">SEND OTP</button>
                </div>
              :<div className='pswd_reset_form_btn'>
                  <button type="submit">VERIFY</button>
              </div>)
              :
              (<div className='loading_button pswd_reset_form_btn'>Loading...</div>)
           }

            
          </form>
        </div>
        <div className='pswd_reset_img_container'>
          <img src={logo} alt="Alconesy Logo" />
        </div>
      </div>
      </div>
    
  );
};

export default PasswordReset;
