import React  from "react";
import "./Events.css";
import Event_card1 from "../../widgets/Event_rec_card/Event_rec_card"; // Import default export
import secA from "../../asset/secA.png";
import secB from "../../asset/secB.png";
import secC from "../../asset/secC.png";
import secD from "../../asset/secD.png";
import secE from "../../asset/secE.png";
import secF from "../../asset/secF.webp";
import { useEffect } from "react";

const Events = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    }, []);
  return (
    <div className="full-page-bg">
      <div className="event_heading">E V E N T S</div>
          <div className="Eee__event-rules">
            <span className="event_rule_note">NOTE : </span><span className="event_rules_inst">Check session schedules to avoid conflicts.</span>
          </div>
          {/* <div className='acco_container'>
           <div className='acco_container_heading'>EVENT RULES</div>
           <div className='acco_paras'>
           <div className='acco_p1'>
        <p>1. Events will be organized into sessions, and participants are advised to review the event time slots carefully to avoid scheduling conflicts.</p>
        <p>2. While registering, please ensure that you check the event timings and register accordingly.</p>
        <p>3. Advance registration is required for all participants to attend the symposium.</p>
        <p>4. Punctuality is expected for all sessions and events.</p>
        <p>5. Participants are expected to show respect towards the judges, organizers, and fellow participants.</p>
        <p>6. Any form of academic dishonesty will lead to disqualification.</p>
        <p>7. Teams may consist of one or two members, as per the rules of each event.</p>
        <p>8. Substituting team members after registration may not be permitted.</p>
      </div>
    </div>
</div> */}

            

      <div className="event_cards">
        <Event_card1 image={secA} text="PAPER PURSUIT" targetUrl="/events_paper_p" />
        <Event_card1 image={secB} text="PROJECTOPOLY" targetUrl="/events_Project" />
        <Event_card1 image={secC} text="FRAME FLIX" targetUrl="/events_Frame" />
        <Event_card1 image={secD} text="CODEGLOW" targetUrl="/events_Treasure" />
        <Event_card1 image={secE} text="CIRCUIT CHARADES" targetUrl="/events_Circuit" />
        <Event_card1 image={secF} text="BOTBOUNTY" targetUrl="/events_photon" />
      </div>
    </div>

  );
};

export default Events;
