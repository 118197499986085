import React, { useState, useRef, useEffect } from "react";
import "./Payment.css";
import payment_heading from "../../asset/payment.svg";
import bank_icon from "../../asset/Vector.svg";
import gpay from "../../asset/Gpay.svg";
import qr from "../../asset/dummy_qr.png";
import alconesylogo from "../../asset/pay_logo.svg";
import cloud from "../../asset/cloud.svg";
import payment_info from "../../asset/payment_info.png";
import generalQr from '../../asset/249.webp'
import workshopQr from '../../asset/279.webp'
import generalFoodQr from '../../asset/299.webp'
import generalWorkshopQr from '../../asset/499.webp'
import generalFoodWorkshopQr from '../../asset/549.webp'
import { PhotoUpload } from "../../widgets";
import axios from "axios";
import { backend_path } from "../../constants/backend";
import { useNavigate } from "react-router-dom";


const TermsModal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="reg_terms_content_container">
          <div className="reg_terms_heading">PAYMENT DETAILS</div>
          <div className="reg_terms_content">
            <ul>
              <li>
                There is no separate payment for individual events.
              </li>
              <li>
                Once the General registration payment is made, participants can register for any event as per the slot.
              </li>
              <li>
                Payments must be made exclusively online.
              </li>
              <li>
                Registration will be confirmed once payment is successfully received. 
              </li>
              <li>
                Carefully review the rules and regulations before proceeding with payment. 
              </li>
              <li>
                Please attach your payment receipt and retain a copy for future reference.
              </li>
              <li>
                Kindly review the packages assigned before finalizing the payment.
              </li>
            </ul>
          </div>
        </div>
        <div className="reg_form_close_btn">
          <button type="submit" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const Payment = () => {

  const navigate=useNavigate();

  const qrImages ={
    generalRegistration: generalQr,
    workshop: workshopQr,
    generalAndFood: generalFoodQr,  
    generalAndWorkshop: generalWorkshopQr,
    generalRegistrationAndWorkshop: generalFoodWorkshopQr
  }

  

  const [formValues, setFormValues] = useState({
    selectedPackage: "",
    totalCost:0,
    transId:"",
  });
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedFileName, setSelectedFileName] = useState(""); // New state to store the file name
  const fileInputRef = useRef(null);
  const [fileDetails, setFileDetails] =useState({});
  const [selectedQrImage,setSelectedQrImage]=useState(qr);

  const[loading,setLoading]=useState(false)

  const costMapping = {
    generalRegistration: 249,
    workshop: 279,
    generalAndFood: 299,
    generalAndWorkshop: 499,
    generalRegistrationAndWorkshop: 549,
  };

  // Handle Checkbox Selection
  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      // [name]: checked,
      totalCost: checked ? costMapping[value] : costMapping[value],
      selectedPackage: checked ? value: "",
    }));
    let qrImageSrc=value;
    setSelectedQrImage(qrImages[qrImageSrc])
  
  };

  // File Upload Handling
  const handleBrowseClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFileName(file.name); // Update the selected file name
      setFileDetails(file);

      const allowedTypes = ['image/jpeg', 'image/png'];
      const maxSize = 200 * 1024; // 200 KB in bytes
  
      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        alert('Only JPEG and PNG images are allowed!');
        event.target.value = null; // Clear the file input
        setFileDetails('');
        setSelectedFileName('');
        return; // Stop further processing
      }
  
      // Validate file size
      if (file.size > maxSize) {
        alert('File size should be less than 200 KB!');
        event.target.value = null; // Clear the file input
        setFileDetails('');
        setSelectedFileName('');
        return; // Stop further processing
      }
    }
  };


  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    
    const newErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && typeof formValues[key] !== "boolean") {
        newErrors[key] = "This field is required";
      }
    });


    if(!selectedFileName){
      newErrors["selectedFileName"]="Upload Your Transaction Proof";
      setLoading(false)
    }
    if (!formValues.selectedPackage) {
      newErrors.selectedPackage = "Please select a package.";
      setLoading(false)
    }
    if (!selectedFileName) {
      newErrors.selectedFileName = "Upload Your Transaction Proof.";
      setLoading(false)
    }
    if (!formValues.transId) {
      newErrors.transId = "Please enter Transaction ID.";
      setLoading(false)
    }
    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      setLoading(false)
    } else {
      setErrors({});
      console.log("Form submitted successfully with values:", formValues);
      console.log("Selected file:",fileDetails);
      console.log(sessionStorage.frompage)
      let packcode;
      if(formValues.selectedPackage=="generalRegistration"){
        packcode=1;
      }else if(formValues.selectedPackage=="workshop"){
        packcode=2;
      }else if(formValues.selectedPackage=="generalAndFood"){
        packcode=3;
      }else if(formValues.selectedPackage=="generalAndWorkshop"){
        packcode=4;
      }else if(formValues.selectedPackage=="generalRegistrationAndWorkshop"){
        packcode=5;
      }
      if (sessionStorage.frompage==1) {
        console.log("from page 1",sessionStorage)
        try {
          const payload={
            city:sessionStorage.city.trim(),
            clg:sessionStorage.clg.trim(),
            dept:sessionStorage.dept.trim(),
            email:sessionStorage.email.trim(),
            gender:sessionStorage.gender.trim(),
            name:sessionStorage.name.trim(),
            oct21:sessionStorage.oct21,
            oct22:sessionStorage.oct22,
            phone:sessionStorage.phone.trim(),
            pswd:sessionStorage.pswd.trim(),
            refcode:sessionStorage.refcode.trim(),
            year:sessionStorage.year.trim(),
            transid:formValues.transId.trim(),
            package:packcode,
            totalcost:formValues.totalCost,
          }
          const fileReader = new FileReader();
          fileReader.onload = async function () {
            const fileBase64 = fileReader.result; // Get the Base64 encoded file
            payload.proof=fileBase64

          const res=await axios.post(`${backend_path}/register`,
            payload,
            
          )
          console.log(res.data.status)
          if(res.data.status=="Success"){
            alert("Registration Successfull");
            sessionStorage.clear();
            navigate("/")
          }else{
            setLoading(false)
            alert("Oops! Try after sometime...");
          }
        }
          fileReader.readAsDataURL(fileDetails);
        } catch (error) {
          console.log(error)
        }
      }else if(sessionStorage.frompage==2){
        //logic for second payment
        console.log("second payment");
        
        try {
          const payload={
            
            transid:formValues.transId.trim(),
            package:packcode,
            totalcost:formValues.totalCost,
          }
          const fileReader = new FileReader();
          fileReader.onload = async function () {
            const fileBase64 = fileReader.result; // Get the Base64 encoded file
            payload.proof=fileBase64

          const res=await axios.post(`${backend_path}/secondpayment`,
            payload
          )
          // console.log("second payment: ",res.data.status)
          if(res.data.status=="Success"){
            alert("Payment Successful");
            sessionStorage.clear();
            navigate("/")
          }else{
            setLoading(false)
            alert("Oops! Try after sometime...");
          }
        }
        fileReader.readAsDataURL(fileDetails);
        } catch (error) {
          console.log(error)
        }
      }
    }
  };

  const [genflag,setGenflag]=useState(0)
  const [workflag,setWorkflag]=useState(0)

  useEffect(()=>{
    const checkAndFetchData = async () => {
      const res=await axios.post(`${backend_path}/getpackage`)
      console.log(res.data)
      setGenflag(res.data.general)
      setWorkflag(res.data.workshop)
    }
    if(sessionStorage.frompage){
      checkAndFetchData();
      //check login
      //access endpoint for getting values of genreg and workshop      console.log("frompage 2")
    }else{
      alert("You should not be here!, register your events dude...")
      navigate("/")
    }
  },[])

  return (
    <div className="Eee__pay-main">
      <div className="Eee__pay-heading">
        <img className="pay_main" src={payment_heading} alt="Payment" />
      </div>

      <div className="Eee__pay_inst-delt">
        <div className="Eee__pay-inst">
          <div>
          <div className="Eee__pay-inst-hd">
            <p>PAYMENT INSTRUCTION</p>
          </div>
          <div className="pay_package_instruction">
              <ol>
                <li>General Registration: ₹249</li>
                <ul>
                  <li>
                    Includes access to all events and refreshments for both days.
                  </li>
                </ul>
                <li>Workshop: ₹279</li>
                <ul>
                  <li>
                    Participants can attend the workshop on the second day (October 23) with refreshments provided twice.
                  </li>
                </ul>
                <li>General Registration + Food: ₹299</li>
                <ul>
                  <li>
                    Includes all events, refreshments for two days and lunch on the first day only (October 22).
                  </li>
                </ul>
                <li>General Registration + Workshop: ₹499</li>
                <ul>
                  <li>
                    Covers all events of Day 1, workshop on Day 2 and refreshments for two days.
                  </li>
                </ul>
                <li>General Registration + Food + Workshop: ₹549</li>
                <ul>
                  <li>
                    Covers all events of Day 1, workshop on Day 2, refreshments for two days and lunch on the first day (October 22).
                  </li>
                </ul>
              </ol>
              </div>
              </div>
          <div className="Eee__pay-inst-cont">
            <div className="Eee__pay-icon-holder">
              <div className="Eee__pay-bank-icon">
                <img src={bank_icon} alt="Bank Icon" />
              </div>
            </div>
            <div className="Eee__pay-inst-form">
              <p className="Eee__pay-inst-form-p">
                Account Number : 50100644981528
              </p>
              <p className="Eee__pay-inst-form-p">IFC Code : HDFC0007156</p>
              <p className="Eee__pay-inst-form-p">
                Branch Name : KOVILOOR ROAD BRANCH KARAIKUDI
              </p>
            </div>
          </div>
        </div>

        <div className="Eee__pay-det">
          <div className="Eee__pay-det-hd">
            <p>PAYMENT DETAILS</p>
          </div>
          <p className="Eee__pay-det-cont Eee__pay-inst-p">
            Select One
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true); // Open the modal when the link is clicked
              }}
            >
              <img
                src={payment_info}
                alt="payment Info"
                className="payment_info_icon"
              />
            </a>
          </p>
          <div className="Eee__pay-select-main">
          <div>
              <input
                className="input-radio"
                type="radio"
                id="generalRegistration"
                name="registration"
                value="generalRegistration"
                disabled={genflag === 1}
                checked={formValues.generalRegistration}
                onChange={handleCheckboxChange}
              />
              <label
                htmlFor="generalRegistration"
                className={`Eee__pay-checkbox pay_radio_btn ${
                  formValues.generalRegistration ? "checked" : ""
                } ${genflag==1?"disabled_radio":""}`}
              >
                General Registration
              </label>
            </div>
            <div>
              <input
                className="input-radio"
                type="radio"
                id="workshop"
                name="registration"
                value="workshop"
                disabled={workflag === 1}
                checked={formValues.workshop}
                onChange={handleCheckboxChange}
              />
              <label
                htmlFor="workshop"
                className={`Eee__pay-checkbox pay_radio_btn ${
                  formValues.workshop ? "checked" : ""
                } ${workflag==1?"disabled_radio":""}`}
              >
                Workshop
              </label>
            </div>

            

            {(sessionStorage.frompage==1) &&(<div>
              <input
                className="input-radio"
                type="radio"
                id="generalAndFood"
                name="registration"
                value="generalAndFood"
                checked={formValues.generalAndFood}
                onChange={handleCheckboxChange}
              />
              <label
                htmlFor="generalAndFood"
                className={`Eee__pay-checkbox pay_radio_btn ${
                  formValues.generalAndFood ? "checked" : ""
                }`}
              >
                General Registration + Food
              </label>
            </div>)}

            {(sessionStorage.frompage==1) &&(<div>
              <input
                className="input-radio"
                type="radio"
                id="generalAndWorkshop"
                name="registration"
                value="generalAndWorkshop"
                checked={formValues.generalAndWorkshop}
                onChange={handleCheckboxChange}
              />
              <label
                htmlFor="generalAndWorkshop"
                className={`Eee__pay-checkbox pay_radio_btn ${
                  formValues.generalAndWorkshop ? "checked" : ""
                }`}
              >
                General Registration + Workshop
              </label>
            </div>)}

            {(sessionStorage.frompage==1) &&(<div>
              <input
                className="input-radio"
                type="radio"
                id="generalRegistrationAndWorkshop"
                name="registration"
                value="generalRegistrationAndWorkshop"
                checked={formValues.generalRegistrationAndWorkshop}
                onChange={handleCheckboxChange}
              />
              <label
                htmlFor="generalRegistrationAndWorkshop"
                className={`Eee__pay-checkbox pay_radio_btn ${
                  formValues.generalRegistrationAndWorkshop ? "checked" : ""
                }`}
              >
                General + Food + Workshop
              </label>
            </div>)}
            {errors.selectedPackage && <p className="pay_error-message">{errors.selectedPackage}</p>}

            <div className="full-checkbox Eee__pay-checkbox">
              Total: ₹{formValues.totalCost}
            </div>
          </div>

          <div className="Eee__pay-icon-holder">
            <div className="Eee__pay-det-gpay">
              <img className="Eee__gpay-svg" src={gpay} alt="Gpay" />
            </div>
          </div>

          <div className="Eee__gpay_qr_container">
            <div className="Eee__gpay-svg">
              <img src={selectedQrImage} alt="Gpay QR" />
            </div>
            <div className="Eee__gpay-aside-inp">
              <p className="Eee__gpay-p">6381704841</p>
              <p className="Eee__gpay-p">9443164697</p>
            </div>
          </div>
        </div>
      </div>

      <div className="Eee__pay-alconesy">
        <div className="Eee__pay-aloconesy-svg">
          <img src={alconesylogo} alt="Alconesy" />
        </div>
        <div className="Eee__pay-cloud-container">
          <div className="Eee__pay-cloud-svg">
            <PhotoUpload />
            <img className="pay__cloud_img" src={cloud} alt="Upload" />
          </div>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".jpeg, .jpg, .png" 
            onChange={handleFileChange}
          />

          <button className="Eee__pay-browse" onClick={handleBrowseClick}>
            {" "}
            Upload file
          </button>

          {/* Display the selected file name if available */}
          {selectedFileName && (
            <p className="pay__cloud-p-small pay_selected_file_name">
              Selected file: {selectedFileName}
            </p>
          )}

          <p className="pay__cloud-p">Upload your payment receipt</p>
          <p className="pay__cloud-p-small">Supported formats: JPEG, PNG(max-size:200KB)</p>
        </div>
        <div className="Eee__pay-aloconesy-svg">
          <img src={alconesylogo} alt="Alconesy" />
        </div>
      </div>
      {errors.selectedFileName && <p className="pay_error-message file_err">{errors.selectedFileName}</p>}
      <form>
      <div className="Eee__pay__transistion-holder">
        
        <input
          className="Eee__pay-inst-inp"
          type="text"
          required
          name="transId"
          placeholder="Transaction ID"
          onChange={handleChange}
        />
        
        
      </div>
      {errors.transId && <p className="pay_error-message trans_err">{errors.transId}</p>}
      <div className="Eee__pay-button">
        {!loading?(<button
          className="Eee__pay-sbt-button"
          type="submit"
          onClick={handleSubmit}
        >
          SUBMIT
        </button>)
        :
        (<div className="loading_button">Loading...</div>)
        }
      </div>
      
      </form>
      <TermsModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default Payment;