import React, { useState, useEffect } from "react";
import "./Description.css";
import { Meta_cont } from "../../constants/description";
import { backend_path } from "../../constants/backend";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Meta = () => {
  const [activeTab, setActiveTab] = useState(Meta_cont.tabs[0]);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [register_flag,setRegister_flag]=useState(0);
  const [auth,setAuth]=useState(0);
  const navigate=useNavigate();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleArrowClick = (direction) => {
    const currentIndex = Meta_cont.tabs.indexOf(activeTab);
    let newIndex;

    if (direction === "left") {
      newIndex =
        (currentIndex - 1 + Meta_cont.tabs.length) % Meta_cont.tabs.length;
    } else {
      newIndex = (currentIndex + 1) % Meta_cont.tabs.length;
    }

    setActiveTab(Meta_cont.tabs[newIndex]);
  };

  const getTabContent = () => {
    switch (activeTab) {
      case "Description":
        return Meta_cont.descriptionText;

      case "Rules":
        return (
          <ul>
            {Meta_cont.rules.content.map((rule, index) => (
              <li key={index}>{rule}</li>
            ))}
          </ul>
        );

      case "Rounds":
        return (
          <div>
            {Meta_cont.rounds.content.map((round, index) => (
              <div key={index}>
                <h3>{round.title}</h3>
                <ul>
                  {round.details.map((detail, detailIndex) => (
                    <li key={detailIndex}>{detail}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        );

      case "Schedule":
        return (
          <ul>
            {Meta_cont.schedule.content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        );

      case "Contacts":
        return (
          <div>
            <p>Phones:</p>
            <ul>
              {Meta_cont.contactDetails.phones.map((phone, index) => (
                <li key={index}>{phone}</li>
              ))}
            </ul>
            <p>Emails:</p>
            <ul>
              {Meta_cont.contactDetails.emails.map((email, index) => (
                <li key={index}>{email}</li>
              ))}
            </ul>
          </div>
        );

      default:
        return Meta_cont.descriptionText;
    }
  };

  // Handle swipe gestures
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    // Detect swipe direction
    if (touchStart - touchEnd > 50) {
      // Swiped left
      handleArrowClick("right");
    }

    if (touchEnd - touchStart > 50) {
      // Swiped right
      handleArrowClick("left");
    }
  };

  const handleRegister= async ()=>{
    if(auth==0){
        alert("You haven't logged in...")
        navigate("/login")
    }else if(auth==1){
        //getregonload
        const res = await axios.post(`${backend_path}/regevent`, { event: 8 } );
        console.log(res.data);
        if(res.data.status=="no_gen_reg"){
            console.log("To Payment Page");
            alert("You haven't paid for General registration")
            sessionStorage.setItem("frompage",2)
            navigate("/payment")
        }else if (res.data.status=="Success"){
            setRegister_flag(1)
            console.log("Registered")
        }
    }
}

useEffect(()=>{
    const fetchData = async () => {
        try {
        //   console.log("Use CIne: ",auth);
          const res = await axios.post(`${backend_path}/getregonload`, { event: 8 } );
          console.log(res);
          if(res.data.reg==0 || res.data.reg==1){
            // console.log("authorized")
            setAuth(1)
          }
          if(res.data.reg===1){
            setRegister_flag(1)
            console.log("Registered");
          }
        } catch (error) {
          console.error("Error fetching data", error);
        }
    };
    
      fetchData();
},[])

  // Attach touch event listeners
  useEffect(() => {
    const descriptionBox = document.querySelector(".description-box");

    if (descriptionBox) {
      descriptionBox.addEventListener("touchstart", handleTouchStart);
      descriptionBox.addEventListener("touchmove", handleTouchMove);
      descriptionBox.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (descriptionBox) {
        descriptionBox.removeEventListener("touchstart", handleTouchStart);
        descriptionBox.removeEventListener("touchmove", handleTouchMove);
        descriptionBox.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [touchStart, touchEnd]);

  return (
    <div className="page-background">
      <div className="description-box">
        <h1 className="event-title">{Meta_cont.headerTitle}</h1>
        <h2 className="description-header">
          {Meta_cont[activeTab.toLowerCase()]?.title || "DESCRIPTION"}
        </h2>

        <p className="description-text">{getTabContent()}</p>

        <div className="tab-menu-container">
        <div className="arrow arrow-left" onClick={() => handleArrowClick('left')}>&#9664;</div>
          <div className="tab-menu">
            {Meta_cont.tabs.map((tabName, index) => (
              <React.Fragment key={index}>
                <div
                  className={`tab-item ${
                    activeTab === tabName ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(tabName)}
                >
                  <span>{tabName}</span>
                </div>
              </React.Fragment>
            ))}
          </div>
          <div className="arrow arrow-right" onClick={() => handleArrowClick('right')}>&#9654;</div>
        </div>
      </div>

      <div className="register-button-container">
          {register_flag==1 && (<div className="registered-div" >Registered</div>)}
          {register_flag==0 && (<button className="register-button" onClick={handleRegister}>{Meta_cont.registerButtonText}</button>)}
          
        </div>
        {/* <div>
            
                <div className="workshop-registered-message">
                    Registered
                </div>
          

            <div className="workshop-after-register"></div>
        </div> */}
    </div>
  );
};

export default Meta;
