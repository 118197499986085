import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import "./Events_photon.css";
import Event_sec_card from '../../widgets/Events_section_card/Events_section_card'; // Import default export
import secF1 from '../../asset/secF-1.webp';
import secF2 from '../../asset/secF-2.webp';

const Events = () => {
  // const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 

  return (
    <div className="secF_full-page-bg">
    <div className='secF_event_heading'> B O T B O U N T Y <span className="heading-gap"> </span></div>
    <div className='secF_event_cards'>
      <Event_sec_card 
        image={secF1} 
        text="SCAVENGER HUNT" 
        subtext="DAY 2 - 3.00 pm"
        targetUrl="/scavenjer"
      />
      <Event_sec_card 
        image={secF2} 
        text="ROBO FUSSBALL" 
        subtext="DAY 2 - 2.00 pm"
        targetUrl="/robo"
      />
     
    </div>
  </div>
  
  );
};

export default Events;
