import Genral from '../asset/genral-sec.svg';
import Joint from '../asset/joint-sec.svg';
import DrBaskaran from '../asset/baskar.webp';
import ProfRamadas from '../asset/Prof. K. Ramadas.png';
import Fourth1 from '../asset/Fourth_1.webp';
import Fourth2 from '../asset/Fourth_2.webp';
import Fourth3 from '../asset/Fourth_3.webp';
import Fourth4 from '../asset/Fourth_4.webp';
import Fourth5 from '../asset/Fourth_5.webp';
import Fourth6 from '../asset/Fourth_6.png';
import Fourth7 from '../asset/Fourth_7.png';
import Fourth8 from '../asset/Fourth_8.png';
import Third1 from '../asset/Third_1.webp';
import Third2 from '../asset/Third_2.png';
import Third3 from '../asset/Third_3.png';
import Third4 from '../asset/Third_4.png';
import Third5 from '../asset/Third_5.png';
import Third6 from '../asset/Third_6.png';
import Third7 from '../asset/Third_7.png';
import Third8 from '../asset/Third_8.png';
import Second1 from '../asset/Second_1.png';
import Second2 from '../asset/Second_2.png';
import Second3 from '../asset/Second_3.webp';
import Second4 from '../asset/Second_4.webp';
import Second5 from '../asset/Second_5.webp';
import Second6 from '../asset/Second_6.webp';
import Second7 from '../asset/Second_7.webp';
import Second8 from '../asset/Second_8.webp';

export const principal = [
    {
        image: DrBaskaran,
        name: "Dr. K. Baskaran",
        designation: "PRINCIPAL / HOD",
        designation_img: Genral,
        link: "" // Add the link if available
    },
];

export const teamStaffs = [
    {
        image: ProfRamadas,
        name: "Prof. K. Ramadas",
        designation: "STAFF INCHARGE / ASSOCIATIVE PROFESSOR",
        designation_img: Genral,
        link: "" // Add the link if available
    },
];

export const teamFourth = [
    {
        image: Fourth1,
        name: "MANISANKAR S",
        designation: "GENERAL SECRETARY",
        designation_img: Genral,
        link: "https://www.linkedin.com/in/manisankarseee?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Fourth2,
        name: "KAVIYA M",
        designation: "JOINT SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/kaviya-murugesan-481352301"
    },
    {
        image: Fourth3,
        name: "VIVEKANANDHAN S",
        designation: "EVENT SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/vivekanandhan-s-647b9132b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Fourth4,
        name: "VICTORIA P",
        designation: "EVENT SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/victoria05"
    },
    {
        image: Fourth5,
        name: "HITAESH KANNA S",
        designation: "ACCOUNT SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/hitaesh-kanna-360778226?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Fourth6,
        name: "DARSHI R SHAH",
        designation: "ACCOUNT SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/darshi-r-shah-4004352b5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
    },
    {
        image: Fourth7,
        name: "RAMESH M",
        designation: "WEBSITE SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/ramesh-m-176747202?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Fourth8,
        name: "VAISHNAVI K",
        designation: "WEBSITE SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/vaishnavi-kalaiselvan-6a1a41315?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
];

export const teamThird = [
    {
        image: Third1,
        name: "AKILAN S",
        designation: "EVENT SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/akilan-s-402246262?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Third2,
        name: "LEENAH SHIREEN SR",
        designation: "EVENT SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/leenah-shireen-3513b72a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Third3,
        name: "AKASH K",
        designation: "ACCOUNT SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/akash-kannan-294169270?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Third4,
        name: "VIJAYALAKSHMI R",
        designation: "ACCOUNT SECRETARY",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/vijayalakshmi-rajendran-a223a626a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Third5,
        name: "KARUPPUPANDIYAN S",
        designation: "WEBSITE SECRETARY",
        designation_img: Joint,
        link: "" 
    },
    {
        image: Third6,
        name: "ANULEKHA PANDI S",
        designation: "WEBSITE SECRETARY",
        designation_img: Joint,
        link: "https://in.linkedin.com/in/anulekha-pandi-81097326a"
    },
    {
        image: Third7,
        name: "SUDARSAN S",
        designation: "EXECUTIVE",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/sudarsan-s-1209s?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Third8,
        name: "SUBA SHREE K",
        designation: "EXECUTIVE",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/subashree-kumaresan-632bb826a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
];

export const teamSecond = [
    {
        image: Second1,
        name: "ARUL S",
        designation: "COORDINATOR",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/arul-s-b476562a5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Second2,
        name: "JAYASHREE JS",
        designation: "COORDINATOR",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/jayashree-js-9263a62a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Second3,
        name: "LOGESHWARI M",
        designation: "COORDINATOR",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/logeshwari-m-6356562a5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Second4,
        name: "MOULEESWARAN M S",
        designation: "COORDINATOR",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/mouleeswaran-m-s-4083a92a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Second5,
        name: "PRAVEEN MAARI M",
        designation: "COORDINATOR",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/praveen-maari-31664732b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Second6,
        name: "RAJAN SM R",
        designation: "COORDINATOR",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/rajan-sm-r-a1706a2a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Second7,
        name: "SRI SURYA P",
        designation: "COORDINATOR",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/shravani-s-7003a2216?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        image: Second8,
        name: "THRISHNA M",
        designation: "COORDINATOR",
        designation_img: Joint,
        link: "https://www.linkedin.com/in/thushara-p-5502b92a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
];
