import React from 'react';
import "./Events_Treasure.css";
import Event_sec_card from '../../widgets/Events_section_card/Events_section_card'; // Import default export
import secD1 from '../../asset/secD-1.png';
import secD2 from '../../asset/secD-2.png';
import secD3 from '../../asset/secD-3.png';

const Events = () => {
  return (
    <div className="secD_full-page-bg">
    <div className='secD_event_heading'>C O D E G L O W  <span className="heading-gap"> </span> </div>
    <div className='secD_event_cards'>
      <Event_sec_card 

        image={secD1} 
        text="ERROR EVASION" 
        subtext="DAY 2 - 11.15 am"
        targetUrl="/error"
      />
      <Event_sec_card 
        image={secD2} 
        text="DIALUX" 
        subtext="DAY 2 - 11.15 am"
        targetUrl="/dialux"
      />
    </div>
  </div>
  
  );
};

export default Events;
