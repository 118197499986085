import React, { useEffect } from 'react'
import './Dashboard.css'
import { useState } from 'react'
import qr from '../../asset/dummy_qr.png'
import {eventNames} from '../../constants/description'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { backend_path } from '../../constants/backend';

const Dashboard = () => {
  const [name,setName]=useState('');
  const [id,setId]=useState('');
  const [collegeName,setCollegeName]= useState('');
  const [selectedDates,setSelectedDates]= useState({oct21:0,oct22:0});
  const [email,setEmail]= useState('');
  const [qrcode,setQRcode]=useState('')
  const [events,setEvents]=useState({event1:0,event2:0,event3:0,event4:0,event5:0,event6:0,event7:0,event8:0,event9:0,event10:0,event11:0,event12:0,event13:0,event14:0,workshop:0})
  const navigate = useNavigate();

  const logout_handler=async()=>{
    console.log("out")
    const res= await axios.get(`${backend_path}/logout`,{},);
    console.log(res);
    if(res.data.Status==="Success"){
      sessionStorage.clear()
      navigate("/")
    }
  }

  useEffect(()=>{
    const fetchDashboard=async ()=>{
      const res=await axios.get(`${backend_path}/dashboard`);
      // console.log(res.data.qr);
      
      setEmail(res.data.participant.email)
      setId(res.data.participant.id)
      setName(res.data.participant.name)
      setCollegeName(res.data.participant.clg)
      setSelectedDates(res.data.participant.selectedDates)
      setEvents(res.data.participant.events)
      setQRcode(res.data.participant.qr)
    }

    fetchDashboard();
  },[])

  return (
    <div className='dashboard'>
      <div className='dash_whole_container'>
        <div className='dash_welcome'>
            WELCOME , {name}
        </div>
        <div className='dash_details'>
          <div className='dash_qr_code'>
            <img className='dash_reg_qr' src={`data:image/png;base64,${qrcode}`} alt="Registration QR" />
          </div>
          <div className='dash_par_details'>
            <p className='dash_reg_clg_name'><span className='dash_reg_clg'>COLLEGE : </span>{collegeName}</p>
            <p className='dash_reg_accommodation'><span className='dash_reg_accommod'>ACCOMMODATION : </span>{selectedDates.oct21==0 ?"" :"Oct20"} - {selectedDates.oct22==0 ?"" :"Oct22"}</p>
            <p className='dash_email'><span className='dash_reg_email'>EMAIL : </span>{email}</p>
          </div>
        </div>
        <p className='dash_reg_id'>{id}</p>
        <div className='dash_selected_event'>
          <span className='dash_reg_event'>REGISTERED EVENT : </span>
          <div className='dash_events_list_container'>
          <ul className='dash_events_list'>
            
            {events.event1==1 && (<li >{eventNames.event1}</li>)} 
            {events.event2==1 && (<li >{eventNames.event2}</li>)} 
            {events.event3==1 && (<li >{eventNames.event3}</li>)} 
            {events.event4==1 && (<li >{eventNames.event4}</li>)} 
            {events.event5==1 && (<li >{eventNames.event5}</li>)} 
            {events.event6==1 && (<li >{eventNames.event6}</li>)} 
            {events.event7==1 && (<li >{eventNames.event7}</li>)} 
            {events.event8==1 && (<li >{eventNames.event8}</li>)} 
            {events.event9==1 && (<li >{eventNames.event9}</li>)} 
            {events.event10==1 && (<li >{eventNames.event10}</li>)} 
            {events.event11==1 && (<li >{eventNames.event11}</li>)} 
            {events.event12==1 && (<li >{eventNames.event12}</li>)} 
            {events.event13==1 && (<li >{eventNames.event13}</li>)} 
            {events.event14==1 && (<li >{eventNames.event14}</li>)} 
            
            {events.workshop==1 && (<li >WORKSHOP</li>)} 
          </ul>
          </div>
        </div>
        <div className='dash_button'>
            <button onClick={logout_handler} className='dash_logout_btn'>LOGOUT
            </button>
        </div>
      </div>
    </div>
  )
}

export default Dashboard