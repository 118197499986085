import React from 'react'
import './Accomodation.css'
const Accomodation=()=> {
  return (
    <div className='acco_bg' >
    <div className='acco_heading'>Accomodation<span className="heading-gap"></span>AND<span className="heading-gap"></span>FOOD</div>
    <div className='acco_container'>
        <div className='acco_container_heading'>ACCOMMODATION RULES </div>
        <div className='acco_paras'>
          <div className='acco_p1'>
            <p>1. Accommodation registration must be completed during the general   
            registration process.    </p>
          <p>2. Participants must register online to utilize the accommodation 
          services.      </p>
          <p>3. Room allocation will be managed by the hospitality committee, 
          depending on availability.     
          </p>
          <p> 4. Individuals are responsible for the safety of their personal belongings 
          during their stay.      
          </p>
          <p> 5. Accommodation is available only for the nights of October 21 and 22; 
          extensions are not allowed.   
          </p>
          <p> 6. Participants attending the workshop will have access to 
          accommodation on October 22.     
          </p>
          <p> 7. Participants must strictly follow the hostel timings.    
          </p>
          <p> 8. Curfew times: Girls must return by 6:00 PM, and boys by 9:00 PM.    
          </p>
          </div>
          </div>
          
          <div className='acco_container_heading'>FOOD </div>
        <div className='acco_paras'>
          <div className='acco_p1'>
          <p>1. Lunch will be provided only on first day (October 22) for the 
            participants who registered for General Registration with food.       </p>
          <p>2. Refreshments will be provided on both the days.        </p>
          <p>3. efreshments will be available twice a day.     
          </p>
          <p>4. Refreshments will be provided to participants twice for those who 
          register for the workshop on the second day (October 23).        
          </p>
          <p>5. Dinner will be offered as part of the hospitality for an additional 
          charge on first day (October 22)  
          </p>
          <p>6. The second day (October 23) Breakfast and lunch will not be managed 
          by the Hospitality Committee during the event.        
          </p>
          <p>7. The canteen will be accessible to attendees during the event.     
          </p>
          <p>8. Participants have the option to get breakfast and lunch from the 
          canteen.       
          </p>
          <p>9. For those wishing to eat off-site, nearby food shops are available.          
          </p>
    
            
          </div>
        </div>
    </div>
    </div>
    
  )
}

export default Accomodation