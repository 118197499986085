import React, { useState,useRef } from 'react';
import './ChangePassword.css';
import { useNavigate } from 'react-router-dom';
import { backend_path } from "../../constants/backend";
import axios from 'axios';
import logo from '../../asset/alconesy_logo.webp';


const ChangePassword = () => {

  const navigate = useNavigate();  // Initialize useNavigate

  const emailRef = useRef(null);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [submit,setSubmit]=useState(false);
  const [pswdErr,setPswdErr]=useState("")

  const [isOtpValid,setIsValidOtp]=useState(false);

  // const changePasswordFn=(e)=>{
  //   const confirmPasswordInput = e.target.value;
  //   setConfirmPassword(confirmPasswordInput);
  
  //   if (confirmPasswordInput.length === 0) {
  //     setPasswordErrorMessage('');
  //     return;
  //   }
  
  //   if (confirmPasswordInput !== newPassword.slice(0, confirmPasswordInput.length)) {
  //     setPasswordErrorMessage('Passwords do not match');
  //   } else {
  //     setPasswordErrorMessage('');
  //   }
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(sessionStorage.email);
      if(newPassword!=confirmPassword){
        setPswdErr("Passwords don't match")
      }else{
        const res=await axios.post(`${backend_path}/resetpassword`,{email:sessionStorage.email,pswd:newPassword})
        if(res.data.status=="Success"){
          alert("Password updated Successfully")
          navigate("/login")
        }else{
          alert("Oops! Something went wrong")
          navigate("/login")
        }
        
      }
    } catch (error) {
      console.log(error);
    }

  };

  return (
    <div className='change_pass'>
      <div className='change_pass_whole_container'>
        <div className='change_pass_form_container'>
          <div className='change_pass_heading_container'>
            <p className='change_pass_heading'>PASSWORD RESET</p>
          </div>
          <div className='change_pass_description'>
          
              <p className='change_pass_desc'>
              Please enter the new password
            </p> 
          </div>
          <form className='change_pass_form' onSubmit={handleSubmit}>
          
                  <div className='change_pass_form_row'>
                    <input
                        type="password"
                        className='change_pass_form_input'
                        name="new_password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value.trim())}
                        placeholder='new password' required
                    />
                  </div>
                  <div className='change_pass_form_row'>
                    <input
                        type="password"
                        className='change_pass_form_input'
                        name="confirm_password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value.trim())
                        }}
                        placeholder='confirm password' required
                    />
                  </div>
                  {pswdErr && <p className='reg_err_msg'>{pswdErr}</p>}
              <div className='change_pass_form_btn'>
                  <button type="submit">Update</button>
              </div>
          </form>
        </div>
        <div className='change_pass_img_container'>
          <img src={logo} alt="Alconesy Logo" />
        </div>
      </div>
      </div>
    
  );
};

export default ChangePassword;
