import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Sign_in.css';
import usernameLogo from '../../asset/username_vector.webp';
import passwordLogo from '../../asset/password_vector.webp';
import { backend_path } from '../../constants/backend';

const Sign_in = () => {
  axios.defaults.withCredentials = true;
  // State to store input values
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [usrerror, setUsrerror] = useState("");
  const [pswderror, setPswderror] = useState("");

  const navigate = useNavigate();

  // Function to handle sign-in button click
  const handleSignIn = async () => {
    setEmail(email.trim());
    setPassword(password.trim());
    let uflag = 0,
    pflag = 0;
    console.log('Email:', email);
    console.log('Password:', password);
    try {
      if (email.length===0) {
        // console.log("inside")
        setUsrerror("Enter Email");
      } else if(!email.includes("@")){
        setUsrerror("Invalid Email");
      }else {
        uflag = 1;
        setUsrerror("");
      }

      if (password.length === 0) {
        setPswderror("Enter Password");
      } else {
        pflag = 1;
        setPswderror("");
      }

      if (uflag === 1 && pflag === 1) {
        const res = await axios.post(`${backend_path}/login`, {
          email:email,
          pswd:password,
        }
      );
        console.log(res.data.pswd_status)
        if(email==='alconesy.acgcet@gmail.com'&& res.data.pswd_status){
          console.log("admin pass matched");
          navigate("/admin-panel");
        }

        else if (res.data.pswd_status) {
          sessionStorage.setItem("email",res.data.email);
          navigate("/dashboard", { state: { id: res.data.id} });
        }
        else if(res.data.username_not_found){
          setUsrerror("Email not Registered")
        }else {
          setPassword("");
          setPswderror("Incorrect password");
        }
      }
    } catch (error) {
      console.log(error)
    }
  };
  useEffect(()=>{
    console.log("started!")
    axios.get(`${backend_path}/login_verification`,{withCredentials:true})
    .then((res)=>{
      console.log(res)
      if(res.data.token_status==="okay"){
        navigate("/dashboard")
      }
    })
  },[])

  return (
    <>
      <div className='signIn'>
        <div className='signIn_container'>
          <div className='signIn_heading'>SIGN IN</div>
          <div className='signIn_description'>Sign in with email address</div>
          
          <div className='signIn_form'>
            <div className='signIn_input_row'>
              <img src={usernameLogo} alt='username logo' className='username_logo' />
              <input
                type='email'
                className='signIn_input'
                placeholder='Em@il id'
                value={email} // Binding the input value to state
                onChange={(e) => setEmail(e.target.value)} // Update state when input changes
              />
              <p style={{ color: 'red' ,fontFamily:'Lexend',padding:'5px 0 0 5px'}}>{usrerror}</p>
            </div>

            <div className='signIn_input_row'>
              <img src={passwordLogo} alt='password logo' className='password_logo' />
              <input
                type='password'
                className='signIn_input'
                placeholder='Password'
                value={password} // Binding the input value to state
                onChange={(e) => setPassword(e.target.value)} // Update state when input changes
              />
              <p style={{ color: 'red',fontFamily:'Lexend',padding:'5px 0 0 5px' }}>{pswderror}</p>
            </div>
          </div>

          <div className='signIn_login_connection'>
            <div className='signIn_to_login'>
              <a href='./registration'>New User</a>
            </div>
            <div className='signIn_forget_password'>
              <a href='/pass_reset'>Forget Password?</a>
            </div>
          </div>

          <div className='signIn_button'>
            <button className='signIn_btn' onClick={handleSignIn}>
              SIGN IN
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sign_in;
