import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import axios from 'axios';
import {Navbar, Footer} from './Components'
import "./App.css";
import"./index.css";
import {
  Description,
  EventsProject,
  EventsFrame,
  EventsTreasure,
  EventsCircuit,
  EventsPhoton,
  SignIn,
  Registration,
  Events,
  EventsPaperP,
  Home,
  Contact,
  Payment,
  Accomodation,
  Workshop,
  Cine,
  Colloquium,
  Dialux,
  Error,
  Flash,
  Meta,
  Projectexpo,
  Robo,
  Sherlock,
  Snakes,
  Tycoon,
  Teams,
  Scavenjer,
  Dashboard,
  Unlock,
 PasswordReset,
ChangePassword} from './pages'

const App = () => {
  const location = useLocation();
  useEffect(() => {
    document.body.scrollTop=0;
    document.documentElement.scrollTop=0;
    }, [location]);

  axios.defaults.withCredentials = true;

  return (
    <div>
      <Navbar/>
     
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/event1" element={<Description />} />
 
        <Route path="/events_paper_p" element={<EventsPaperP />} />
        <Route path="/events_Project" element={<EventsProject />} />
        <Route path="/events_Frame" element={<EventsFrame/>} />
        <Route path="/events_Treasure" element={<EventsTreasure />} />
        <Route path="/events_Circuit" element={<EventsCircuit />} />
        <Route path="/events_photon" element={<EventsPhoton />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/events" element={<Events/>} />
        <Route path="/accomodation" element={<Accomodation />} />
        <Route path="/workshop" element={<Workshop />} />
        <Route path="/cine" element={<Cine />} />
        <Route path="/colloquium" element={<Colloquium />} />
        <Route path="/description" element={<Description />} />
        <Route path="/dialux" element={<Dialux />} />
        <Route path="/error" element={<Error />} />
        <Route path="/flash" element={<Flash />} />
        <Route path="/meta" element={<Meta />} />
        <Route path="/projectexpo" element={<Projectexpo />} />
        <Route path="/robo" element={<Robo />} />
        <Route path="/sherlock" element={<Sherlock />} />
        <Route path="/snakes" element={<Snakes />} />
        <Route path="/tycoon" element={<Tycoon />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/scavenjer" element={<Scavenjer />} />
        <Route path="/unlock" element={<Unlock />} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/pass_reset" element={<PasswordReset/>} />
        <Route path="/change_password" element={<ChangePassword/>} />
      </Routes>
     <Footer/>
    </div>
  );
};

export default App;
