import React from "react";
import "./Modal.css";

const Modal = ({ heading, pic, content_1, content_2, link, onClose }) => {
  const handleLinkClick = () => {
    console.log("Link clicked:", link); // Debugging line
    if (link) {
      window.open(link, '_blank'); // Open the link in a new tab
    } else {
      alert("No LinkedIn profile available");
    }
  };

  return (
    <div className="tm__modal-container" onClick={onClose}>
      <div className="tm__modal-card" onClick={(e) => e.stopPropagation()}>
        <div className="tm__modal-header">
          <div className="close-btn" onClick={onClose}>
            &times;
          </div>
        </div>
        <div className="tm__modal-content">
          <img src={pic} alt="Profile" className="tm__modal-image" />
          <div className="tm__modal-title">{heading}</div>
          <div className="tm__modal-description">
            {content_1 && <p>{content_1}</p>}
            {content_2 && <p className="tm__modal-description-p">{content_2}</p>}
          </div>
          <div className="tm__modal__button_container">
            {link && ( // Only show the button if the link is not empty
              <button className="tm__modal__button" onClick={handleLinkClick}>
                LinkedIn Profile
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
