import React, { useState,useRef } from 'react';
import './Registration.css';
import { useNavigate } from 'react-router-dom';
import { backend_path } from "../../constants/backend";
import axios from 'axios';
import logo from '../../asset/alconesy_logo.webp';

const TermsModal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className='reg_terms_content_container'>
          <div className='reg_terms_heading'>TERMS AND CONDITIONS</div>
          <div className='reg_terms_content'>
            <ul>
              <li>Registrations must be submitted before the deadline; late entries will not be considered.</li>
              <li>Confirmation of registration occurs only after full payment is received.</li>
              <li>Registration for the events will only be confirmed upon completion of the payment process.</li>
              <li>General Registration grants access to all scheduled events.</li>
              <li>Registrations without payment will be considered incomplete and not confirmed.</li>
              <li>For food and accommodation details, please refer to the accommodation page on the website.</li>
              <li>Participants are expected to arrive promptly.</li>
              <li>Please ensure that you carefully review the payment instructions and event packages provided on the payment page prior to completing your registration.</li>
            </ul>
          </div>
        </div>
        <div className='reg_terms_contact'>CONTACT US</div>
        <div className='reg_terms_contact_details'>
          <ul>
            <li>HITAESH KANNA S - 94431 64697</li>
            <li>DARSHI R SHAH - 63817 04841</li>
          </ul>
        </div>
        <div className='reg_form_close_btn'>
              <button type="submit" onClick={onClose}>Close</button>
            </div>
        
      </div>
    </div>
  );
};

const Registration = () => {

  const navigate = useNavigate();  // Initialize useNavigate

  // Refs for error focus
  const mobileRef = useRef(null);
  const passwordRef = useRef(null);
  const firstNameRef = useRef(null);
  const emailRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState({
    oct21: false,
    oct22: false,
  });

  const [mobileNumber, setMobileNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [collegeName, setCollegeName] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [year, setYear] = useState('');
  const [city, setCity] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [refcodemsg,Setrefcodemsg]=useState('');
  const [emailmsg,SetEmailmsg]=useState('')
  
  const validateMobileNumber = (number) => {
    const regex = /^[6-9]\d{9}$/; 
    return regex.test(number);
  };

  const handlePassword = (e) => {
    const confirmPasswordInput = e.target.value;
    setConfirmPassword(confirmPasswordInput);
  
    if (confirmPasswordInput.length === 0) {
      setPasswordErrorMessage('');
      return;
    }
  
    if (confirmPasswordInput !== password.slice(0, confirmPasswordInput.length)) {
      setPasswordErrorMessage('Passwords do not match');
    } else {
      setPasswordErrorMessage('');
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("oooo")
    if (!validateMobileNumber(mobileNumber)) {
      setErrorMessage('Please enter a valid 10-digit mobile number');
      mobileRef.current.focus();
      return;
    }

    if (password !== confirmPassword) {
      setPasswordErrorMessage('Passwords do not match');
      passwordRef.current.focus();
      return;
    }
    console.log("ooool")

    // Print the form data in dictionary format
    const formData = {
      firstName,
      lastName,
      email,
      mobileNumber,
      gender,
      collegeName,
      departmentName,
      year,
      city,
      accommodationDates: {
        oct21: selectedDates.oct21 ? 1 : 0,
        oct22: selectedDates.oct22 ? 1 : 0,
      },
      password,
      confirmPassword,
      referralCode,
    };
    let validform=true;

    try {
      if(referralCode!=''){
        const res = await axios.post(`${backend_path}/checkreferral`, {referralCode:referralCode.trim()});
        console.log("Hii",res.data.refcodeStatus)
        if(res.data.refcodeStatus==1){
          Setrefcodemsg('')
          validform=true;
        }else{
          Setrefcodemsg("Invalid code")
          validform=false;
        }
      }
      const emailcheck = await axios.post(`${backend_path}/checkemail`, {email:email.trim()});
      console.log(emailcheck.data.emailStatus)
      if(emailcheck.data.emailStatus==0){
        SetEmailmsg('')
        validform=(validform&&true);
      }else{
        SetEmailmsg("Email already exists")
        validform=false;
      }
    } catch (err) {
      console.error("Error during axios post:", err);
    }

    if(validform){
      alert('Move to Payment!');
      // Reset all form fields
      sessionStorage.setItem("name",firstName+" "+lastName)
      setFirstName('');
      setLastName('');
      sessionStorage.setItem("email",email)
      setEmail('');
      sessionStorage.setItem("phone",mobileNumber)
      setMobileNumber('');
      sessionStorage.setItem("gender",gender)
      setGender('');
      sessionStorage.setItem("clg",collegeName)
      setCollegeName('');
      sessionStorage.setItem("dept",departmentName)
      setDepartmentName('');
      sessionStorage.setItem("year",year)
      setYear('');
      sessionStorage.setItem("city",city)
      setCity('');
      sessionStorage.setItem("pswd",password)
      setPassword('');
      setConfirmPassword('');
      sessionStorage.setItem("refcode",referralCode)
      setReferralCode('');
      setTermsAgreed(false);
      sessionStorage.setItem("oct21",selectedDates.oct21)
      sessionStorage.setItem("oct22",selectedDates.oct22)
      setSelectedDates({
        oct21: false,
        oct22: false,
      });
      setErrorMessage('');
      setPasswordErrorMessage('');
      Setrefcodemsg('');
      sessionStorage.setItem("frompage",1)
      navigate('/payment');
    }
    

    console.log("Form Data: ", formData);
  };

  return (
    <div className='registration'>
      <div className='reg_whole_container'>
        <div className='reg_form_container'>
          <div className='reg_heading_container'>
            <p className='reg_heading'>REGISTRATION</p>
          </div>
          <div className='reg_description'>
            <p className='reg_desc'>
              Please enter valid information and ensure the form is filled out
              correctly before submission.
            </p>
          </div>
          <form className='reg_form' onSubmit={handleSubmit}>
            <div className='reg_form_row reg_form_row_sl'>
              <input
                type="text"
                className='reg_form_input_sl'
                name="firstName"
                ref={firstNameRef}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder='First Name' required
              />
              <input
                type="text"
                className='reg_form_input_sl'
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder='Last Name' required
              />
            </div>
            <div className='reg_form_row'>
              <input
                type="email"
                className='reg_form_input'
                name="email"
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Email' required
              />
              {emailmsg && <p style={{ color: 'red' }}>{emailmsg}</p>}
            </div>
            <div className='reg_form_row'>
              <input
                type="text"
                maxLength={10}
                className='reg_form_input'
                ref={mobileRef}
                value={mobileNumber}
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                  setErrorMessage('');
                }}
                placeholder='Phone Number' required
              />
              {errorMessage && <p className='reg_err_msg'>{errorMessage}</p>}
            </div>
            <div className='reg_form_row'>
              <select
                className='reg_form_select'
                name="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                required
              >
                <option value="" disabled>
                  Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className='reg_form_row'>
              <input
                type="text"
                className='reg_form_input'
                name="collegeName"
                value={collegeName}
                onChange={(e) => setCollegeName(e.target.value)}
                placeholder='College Name' required
              />
            </div>
            <div className='reg_form_row'>
              <input
                type="text"
                className='reg_form_input'
                name="departmentName"
                value={departmentName}
                onChange={(e) => setDepartmentName(e.target.value)}
                placeholder='Department Name' required
              />
            </div>
            <div className='reg_form_row'>
              <select
                className='reg_form_select'
                name="year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                required
              >
                <option value="" disabled>
                  Year
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>
            <div className='reg_form_row'>
              <input
                type="text"
                className='reg_form_input'
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder='City' required
              />
            </div>
            <div className='reg_form_row reg_form_date reg_form_row_sl'>
              <div>
                <p className='reg_form_accomadation'>Accommodation date</p>
              </div>
              <div className='checkbox-group'>
                <label
                  className={`checkbox-button ${
                    selectedDates.oct21 ? 'checked' : ''
                  }`}
                >
                  <input
                    type="checkbox"
                    name="oct21"
                    checked={selectedDates.oct21}
                    onChange={() => setSelectedDates({ ...selectedDates, oct21: !selectedDates.oct21 })}
                  />
                  Oct 21
                </label>
                <label
                  className={`checkbox-button ${
                    selectedDates.oct22 ? 'checked' : ''
                  }`}
                >
                  <input
                    type="checkbox"
                    name="oct22"
                    checked={selectedDates.oct22}
                    onChange={() => setSelectedDates({ ...selectedDates, oct22: !selectedDates.oct22 })}
                  />
                  Oct 22
                </label>
              </div>
            </div>
            <div className='reg_form_row'>
              <input
                type="password"
                className='reg_form_input'
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Password' required
              />
            </div>
            <div className='reg_form_row'>
              <input
                type="password"
                className='reg_form_input'
                ref={passwordRef}
                name="confirmPassword"
                value={confirmPassword}
                onChange={handlePassword}
                placeholder='Confirm Password' required
              />
              {passwordErrorMessage && (
                <p className='reg_err_msg'>{passwordErrorMessage}</p>
              )}
            </div>
            <div className='reg_form_row'>
              <input
                type="text"
                className='reg_form_input'
                name="referralCode"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                placeholder='Referral Code'
              />
              {refcodemsg && <p style={{ color: 'red' }}>{refcodemsg}</p>}
            </div>
            <div className='reg_terms_container'>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  name="termsAgreed"
                  checked={termsAgreed}
                  onChange={(e) => setTermsAgreed(e.target.checked)}
                  required
                />
                <span className="styled-checkbox"></span>
                <span className='reg_terms_agree_line'>Agree with</span>
                
                <a href="#" onClick={(e) => {
                  e.preventDefault(); 
                  setShowModal(true); // Open the modal when the link is clicked
                  }}>terms and conditions
                </a>
              </label>
            </div>
            <div className='reg_form_btn'>
              <button type="submit">ENROLL</button>
            </div>
          </form>
        </div>
        <div className='reg_img_container'>
          <img src={logo} alt="Alconesy Logo" />
        </div>
      </div>
        <TermsModal show={showModal} onClose={() => setShowModal(false)} />
      </div>
    
  );
};

export default Registration;
