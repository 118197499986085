import React from 'react';
import './Footer.css';
import mailicon from '../../asset/mailimg.png';
import phoneicon from '../../asset/phoneimg.png';
import instaicon from '../../asset/footer_insta_icon.png';
import linkedinicon from '../../asset/footer_linkedin_icon.png';

const Footer = () => {
  return (
    <div className='footer_container'>
<div className='footer_1st_col'>
      <div className='footer_section'>
        <div className='footer_follow_sec_head'>FOLLOW US</div>
        <div className='footer_follow_sec_icons_hold'>
          <a href="https://www.instagram.com/alconesy_acgcet/" className='footer_follow_sec_icon_wrap'>
            <img src={instaicon} className='footer_follow_sec_icons' alt="Instagram"/>
          </a>
          <a href="https://www.linkedin.com/company/eee-association-of-acgcet/" className='footer_follow_sec_icon_wrap'>
            <img src={linkedinicon} className='footer_follow_sec_icon' alt="LinkedIn"/>
          </a>
        </div>
      </div>

     
     
      {/* Contact Us Section */}
      <div className='footer_section'>
        <div className='footer_contact_head'>CONTACT US</div>
        {/* <a href="mailto:eee@gmail.com" target="_blank" rel="noopener noreferrer">
          <img src={require('../../asset/mailicon.png')} alt="Mail" className='mail-imgs' />
          <div className='footer_email_name'>eee@gmail.com</div>
        </a>
       
        <div className='footer_email_name'>
        <img src={require('../../asset/phoneicon.png')} alt="Mail" className='phone-img' />
          +91 999999999
        </div> */}
        <a href="mailto:alconesy.acgcet@gmail.com" target="_blank" rel="noopener noreferrer">
          <img src={require('../../asset/mailimg.png')} alt="Mail" className='mail-imgs' />
          <div className='footer_email_name'>alconesy.acgcet@gmail.com</div>
        </a>
        <div className='footer_email_name'>
        <img src={require('../../asset/phoneimg.png')} alt="Mail" className='phone-img' />
        <a href="tel:+91 9344131714">+91 9344131714</a>
        </div>
      </div>
      <div className='footer_section'>
        <div className='footer_credits_head'>CREDITS</div>
        <h1>Design</h1>
        <p> <a href="https://arjunprakash22.github.io/solai_dhanush/Dhanush-portfolio/" target="_blank" rel="noopener noreferrer">
             solai dhanush
            </a></p>
        <h2>Developers</h2>
       
        <p>
        <a href="https://www.linkedin.com/in/sri-ram-1459192a2?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
              Sri Ram K
            </a>
          </p>
          <p>
            <a href="https://www.linkedin.com/in/gopinath-s" target="_blank" rel="noopener noreferrer">
              Gopinath S
            </a>
          </p>
          <p>
            <a href="https://www.linkedin.com/in/mohana-mala-025a88245" target="_blank" rel="noopener noreferrer">
              Mohana Mala J
            </a>
          </p>
          <p>
            <a href="https://www.linkedin.com/in/kanishka-m-5655472a0?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
              Kanishka M
            </a>
           
          </p>
        

      </div>
      </div>
      <div className='footer_copyright'>
  
  <div> &copy; eee association {new Date().getFullYear()}</div> 
  <div>Developed by CSE Association</div> 
</div>


    </div>
  );
};

export default Footer;
